import { lazy, useMemo, useContext } from 'react';
import { CustomProvider } from 'rsuite';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import TokenController from './layouts/TokenController/TokenController';
import store from './reducers/store';
import { AppContainer } from './styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeContext, ThemeProvider, MenuProvider } from './contexts';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/es';
import { darkThemeOptions, lightThemeOptions } from './theme/theme';
import './i18n/i18n';
import PublicLayout from './layouts/PublicLayout/PublicLayout';
import PrivateLayout from './layouts/PrivateLayout/PrivateLayout';
import ROUTES from './routes/routes';
import { LazyLoader, Menu } from './components';
import { RoleEnum } from './types/auth';
import RoleController from './layouts/RoleController/RoleController';
import { createTheme, CssBaseline, responsiveFontSizes, ThemeProvider as MaterialThemeProvider } from '@mui/material';
import Snackbar from './components/Snackbar/Snackbar';
import es from 'rsuite/locales/es_ES';
import { esES } from '@mui/material/locale';
import dayjs from 'dayjs';
dayjs.locale('es');

const Login = LazyLoader(lazy(() => import('./pages/Login/Login')));
const ForgotPassword = LazyLoader(lazy(() => import('./pages/ForgotPassword/ForgotPassword')));
const ChangePassword = LazyLoader(lazy(() => import('./pages/ChangePassword/ChangePassword')));

const Dashboard = LazyLoader(lazy(() => import('./pages/Dashboard/Dashboard')));
const Users = LazyLoader(lazy(() => import('./pages/Users/Users')));
const Settings = LazyLoader(lazy(() => import('./pages/Settings/Settings')));
const Smtps = LazyLoader(lazy(() => import('./pages/Smtps/Smtps')));
const Orders = LazyLoader(lazy(() => import('./pages/Orders/Orders')));
const DeliveryNotes = LazyLoader(lazy(() => import('./pages/DeliveryNotes/DeliveryNotes')));
const Settlements = LazyLoader(lazy(() => import('./pages/Settlements/Settlements')));
const DocumentNotifications = LazyLoader(lazy(() => import('./pages/DocumentNotifications/DocumentNotifications')));
const BoxesControl = LazyLoader(lazy(() => import('./pages/BoxesControl/BoxesControl')));
const KilosReport = LazyLoader(lazy(() => import('./pages/KilosReport/KilosReport')));
const Clients = LazyLoader(lazy(() => import('./pages/Clients/Clients')));
const Client = LazyLoader(lazy(() => import('./pages/Client/Client')));
const Suppliers = LazyLoader(lazy(() => import('./pages/Suppliers/Suppliers')));
const Supplier = LazyLoader(lazy(() => import('./pages/Supplier/Supplier')));
const Invoices = LazyLoader(lazy(() => import('./pages/Invoices/Invoices')));
const DocumentManager = LazyLoader(lazy(() => import('./pages/DocumentManager/DocumentManager')));
const PaymentManager = LazyLoader(lazy(() => import('./pages/PaymentManager/PaymentManager')));
const NotFound = LazyLoader(lazy(() => import('./pages/NotFound/NotFound')));
const Carriers = LazyLoader(lazy(() => import('./pages/Carriers/Carriers')));
const Carrier = LazyLoader(lazy(() => import('./pages/Carrier/Carrier')));

const renderPage = (element: JSX.Element, role: RoleEnum) => {
  return (
    <RoleController requiredRole={role} redirect>
      {element}
    </RoleController>
  );
};

const App = () => {
  const { theme: customTheme } = useContext(ThemeContext);
  const theme = useMemo(() => {
    const theme = createTheme(customTheme === 'dark' ? darkThemeOptions : lightThemeOptions, esES);
    return responsiveFontSizes(theme);
  }, [customTheme]);

  return (
    <Provider store={store}>
      <Router>
        <CustomProvider locale={es}>
          <MenuProvider>
            <MaterialThemeProvider theme={theme}>
              <StyledThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                  <AppContainer>
                    <CssBaseline />
                    <TokenController>
                      <Routes>
                        <Route element={<PrivateLayout />}>
                          <Route path={ROUTES.INDEX} element={renderPage(<Dashboard />, RoleEnum.USER)} />
                          <Route path={ROUTES.ADMIN} element={renderPage(<Dashboard />, RoleEnum.USER)} />
                          <Route path={ROUTES.SETTINGS} element={renderPage(<Settings />, RoleEnum.USER)} />
                          <Route path={ROUTES.ORDERS} element={renderPage(<Orders />, RoleEnum.USER)} />
                          <Route path={ROUTES.DELIVERY_NOTES} element={renderPage(<DeliveryNotes />, RoleEnum.USER)} />
                          <Route path={ROUTES.SETTLEMENTS} element={renderPage(<Settlements />, RoleEnum.USER)} />
                          <Route
                            path={ROUTES.DOCUMENT_NOTIFICATIONS}
                            element={renderPage(<DocumentNotifications />, RoleEnum.USER)}
                          />
                          <Route path={ROUTES.USERS} element={renderPage(<Users />, RoleEnum.ADMIN)} />
                          <Route path={ROUTES.SMTPS} element={renderPage(<Smtps />, RoleEnum.ADMIN)} />
                          <Route path={ROUTES.CLIENTS} element={renderPage(<Clients />, RoleEnum.USER)} />
                          <Route path={ROUTES.CLIENT} element={renderPage(<Client />, RoleEnum.USER)} />
                          <Route path={ROUTES.SUPPLIERS} element={renderPage(<Suppliers />, RoleEnum.USER)} />
                          <Route path={ROUTES.SUPPLIER} element={renderPage(<Supplier />, RoleEnum.USER)} />
                          <Route path={ROUTES.INVOICES} element={renderPage(<Invoices />, RoleEnum.USER)} />
                          <Route path={ROUTES.BOXES_CONTROL} element={renderPage(<BoxesControl />, RoleEnum.USER)} />
                          <Route path={ROUTES.KILOS_REPORT} element={renderPage(<KilosReport />, RoleEnum.USER)} />
                          <Route
                            path={ROUTES.DOCUMENT_MANAGER}
                            element={renderPage(<DocumentManager />, RoleEnum.USER)}
                          />
                          <Route
                            path={ROUTES.PAYMENT_MANAGER}
                            element={renderPage(<PaymentManager />, RoleEnum.USER)}
                          />
                          <Route path={ROUTES.CARRIERS} element={renderPage(<Carriers />, RoleEnum.USER)} />

                          <Route path={ROUTES.CARRIER} element={renderPage(<Carrier />, RoleEnum.USER)} />
                        </Route>
                        <Route element={<PublicLayout />}>
                          <Route path={ROUTES.LOGIN} element={<Login />} />
                          <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
                          <Route path={ROUTES.CHANGE_PASSWORD} element={<ChangePassword />} />
                          <Route path={ROUTES.INDEX} element={<Login />} />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </TokenController>
                    <Snackbar />
                  </AppContainer>
                  <Menu />
                </LocalizationProvider>
              </StyledThemeProvider>
            </MaterialThemeProvider>
          </MenuProvider>
        </CustomProvider>
      </Router>
    </Provider>
  );
};

const MQSApp = () => {
  return (
    <ThemeProvider>
      <App />
    </ThemeProvider>
  );
};

export default MQSApp;

import { RoleEnum } from 'src/types/auth';
import ROUTES from 'src/routes/routes';
import { HeaderItemType } from 'src/types/global';

const MENU: HeaderItemType[] = [
  {
    key: 'orders',
    icon: 'home',
    path: ROUTES.ORDERS,
    roles: [RoleEnum.USER, RoleEnum.ADMIN],
  },
  {
    key: 'delivery-notes',
    icon: 'home',
    path: ROUTES.DELIVERY_NOTES,
    roles: [RoleEnum.USER, RoleEnum.ADMIN],
  },
  {
    key: 'settlements',
    icon: 'home',
    path: ROUTES.SETTLEMENTS,
    roles: [RoleEnum.USER, RoleEnum.ADMIN],
  },

  {
    key: 'invoices',
    icon: 'home',
    path: ROUTES.INVOICES,
    roles: [RoleEnum.USER, RoleEnum.ADMIN],
  },
  {
    key: 'manager',
    icon: 'settings',
    roles: [RoleEnum.USER, RoleEnum.ADMIN],
    options: [
      {
        key: 'documents',
        icon: 'home',
        path: ROUTES.DOCUMENT_MANAGER,
        roles: [RoleEnum.USER, RoleEnum.ADMIN],
      },
      {
        key: 'payment-manager',
        icon: 'home',
        path: ROUTES.PAYMENT_MANAGER,
        roles: [RoleEnum.USER, RoleEnum.ADMIN],
      },
      {
        key: 'boxesControl',
        icon: 'home',
        path: ROUTES.BOXES_CONTROL,
        roles: [RoleEnum.USER, RoleEnum.ADMIN],
      },
    ],
  },
  {
    key: 'reports',
    icon: 'settings',
    roles: [RoleEnum.USER, RoleEnum.ADMIN],
    options: [
      {
        key: 'documentNotifications',
        icon: 'home',
        path: ROUTES.DOCUMENT_NOTIFICATIONS,
        roles: [RoleEnum.USER, RoleEnum.ADMIN],
      },
      {
        key: 'kilos-report',
        icon: 'home',
        path: ROUTES.KILOS_REPORT,
        roles: [RoleEnum.USER, RoleEnum.ADMIN],
      },
    ],
  },
  {
    key: 'configuration',
    icon: 'settings',
    roles: [RoleEnum.USER, RoleEnum.ADMIN],
    options: [
      {
        key: 'users',
        icon: 'home',
        path: ROUTES.USERS,
        roles: [RoleEnum.USER, RoleEnum.ADMIN],
      },
      {
        key: 'clients',
        icon: 'home',
        path: ROUTES.CLIENTS,
        roles: [RoleEnum.USER, RoleEnum.ADMIN],
      },
      {
        key: 'suppliers',
        icon: 'home',
        path: ROUTES.SUPPLIERS,
        roles: [RoleEnum.USER, RoleEnum.ADMIN],
      },
      {
        key: 'carriers',
        icon: 'home',
        path: ROUTES.CARRIERS,
        roles: [RoleEnum.USER, RoleEnum.ADMIN],
      },
      {
        key: 'smpts',
        icon: 'home',
        path: ROUTES.SMTPS,
        roles: [RoleEnum.USER, RoleEnum.ADMIN],
      },
    ],
  },
];

export default MENU;

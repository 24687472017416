import React from 'react';
import { CloseOutlined } from '@mui/icons-material';
import { Dialog, AppBar, Toolbar, Typography, IconButton, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Container, Content, Footer, ScrollContainer } from './styles';
import { FullScreenDialogProps } from './types';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = ({ isOpen, title, footer, children, hidePaddings, onClose }: FullScreenDialogProps) => {
  return (
    <Dialog fullScreen open={isOpen} onClose={onClose} TransitionComponent={Transition} disablePortal>
      <Container>
        <AppBar sx={{ position: 'relative', zIndex: 9999 }} color="secondary">
          <Toolbar>
            <Typography sx={{ mr: 2, flex: 1 }} variant="h6" color="inherit">
              {title}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
              <CloseOutlined />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Content includeFooter={!!footer} hidePaddings={hidePaddings}>
          <ScrollContainer hidePaddings={hidePaddings}>{children}</ScrollContainer>
        </Content>
        {footer && <Footer>{footer}</Footer>}
      </Container>
    </Dialog>
  );
};

export default FullScreenDialog;

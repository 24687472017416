import { Typography } from '@mui/material';
import styled from 'styled-components';

export const iconStyles = {
  width: 22,
  zIndex: 1300,
  position: 'absolute',
  right: 0,
};

export const boxStyles = {
  display: 'flex',
  gap: 1,
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
};

export const InputText = styled(Typography)`
  flex: 1;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const OptionText = styled(Typography)`
  height: 48px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

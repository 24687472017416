import { CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import type React from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropZoneContainer, FileContainer, FileName, HelperMessage, HiddenInput, Link } from './styles';
import type { FileDropZoneProps } from './types';
import './i18n';
import { DeleteOutline, DownloadOutlined } from '@mui/icons-material';
import { downloadFile } from 'src/utils/common/file';

// const fileExtTypes = {
//   img: '.jpeg,.jpg,.png,.webp',
//   vid: '.mp4,.mov',
//   file: '.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.jpeg,.jpg,.png,.webp',
//   doc: '.doc,.docx',
// };

const FileDropZone = ({
  files,
  error,
  formats = '.jpeg,.jpg,.png,.webp',
  formatsLabel,
  multiple = false,
  maxMbSize,
  onChange,
}: FileDropZoneProps) => {
  const { t } = useTranslation('dropzone');
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localError, setLocalError] = useState('');
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const convertMegaBytesToBytes = (megaBytes: number) => megaBytes * 1024 * 1024;
  const getFileExtension = (file: File) => file.name.split('.').pop()?.toLowerCase() || '';

  const fileListToArray = (list: FileList): File[] => {
    const result: File[] = [];
    for (let i = 0; i < list.length; i++) {
      const file = list.item(i);
      if (file) result.push(file);
    }
    return result;
  };

  const onChangeFiles = (files: File[]) => {
    setLoading(true);
    const extensions = formats.split(',').map(ext => ext.substring(1).toLowerCase());
    const invalidFormat = files.some(file => !extensions.includes(getFileExtension(file)));

    if (invalidFormat) {
      setLocalError(t('invalidFormat') || '');
      setLoading(false);
      return;
    }

    const exceedsMaximumSize = maxMbSize && files.some(file => file.size > convertMegaBytesToBytes(maxMbSize));
    if (exceedsMaximumSize) {
      setLocalError(t('exceedsMaximumSize', { size: 2 }) || '');
      setLoading(false);
      return;
    }

    setLocalError('');
    onChange(files);
    setLoading(false);
  };

  const onFilesAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) onChangeFiles(fileListToArray(files));
  };

  const downloadFiles = () => {
    if (!files || files.length === 0) return;

    if (files.length === 1) downloadFile(files[0]);
    // TODO: Si hay mas de un archivo, zip
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    stopEvent(event);
    setHover(true);
  };

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    stopEvent(event);
    setHover(false);
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    stopEvent(event);
    const { files } = event.dataTransfer;
    setHover(false);
    if (files) onChangeFiles(fileListToArray(files));
  };

  const stopEvent = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const openFileDialog = () => {
    fileInputRef.current?.click();
  };

  const deleteFiles = () => {
    if (fileInputRef.current) fileInputRef.current.value = '';
    onChangeFiles([]);
  };

  const formatMessage = `${t('formats')}: ${formatsLabel || formats.split(',').join(', ')}.`;
  const maxSizeMessage = maxMbSize ? `${t('maximumSize', { size: maxMbSize })}.` : '';
  const helperMessage = `${formatMessage} ${maxSizeMessage}`;
  const message = localError || error || helperMessage;

  return (
    <>
      <DropZoneContainer
        error={!!(localError || error)}
        hover={hover}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}>
        {loading && <CircularProgress />}
        {!loading && hover && <Typography variant="subtitle1">{t('drop')}</Typography>}
        {!loading && !hover && (
          <>
            {files && files.length > 0 ? (
              <FileContainer>
                <FileName variant="subtitle1">{files.map(file => file.name).join(', ')}</FileName>
                <Tooltip title={t('download')}>
                  <IconButton onClick={downloadFiles}>
                    <DownloadOutlined />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('delete')}>
                  <IconButton onClick={deleteFiles}>
                    <DeleteOutline />
                  </IconButton>
                </Tooltip>
              </FileContainer>
            ) : (
              <>
                <Typography variant="subtitle1">
                  {t('instruction')}
                  <Link onClick={openFileDialog}>{t('here')}</Link>
                </Typography>
              </>
            )}
          </>
        )}
        <HiddenInput ref={fileInputRef} accept={formats} type="file" multiple={multiple} onChange={onFilesAdded} />
      </DropZoneContainer>
      <HelperMessage variant="body2" color={error || localError ? 'red' : 'GrayText'}>
        {message}
      </HelperMessage>
    </>
  );
};

export default FileDropZone;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Carrier, CarriersStore, Rate } from 'src/types/carriers';

const initialState: CarriersStore = {
  carrier: null,
  isCarrierModalOpen: false,
  isRateModalOpen: false,
  rate: null,
};

export const carriersSlice = createSlice({
  name: 'carriers',
  initialState,
  reducers: {
    openCarrierDetail: (state: CarriersStore, action: PayloadAction<Carrier | null>) => {
      const { payload } = action;
      state.isCarrierModalOpen = true;
      state.carrier = payload;
    },
    closeCarrierDetail: (state: CarriersStore) => {
      state.carrier = null;
      state.isCarrierModalOpen = false;
    },
    openRateDetail: (state: CarriersStore, action: PayloadAction<Rate | null>) => {
      const { payload } = action;
      state.isRateModalOpen = true;
      state.rate = payload;
    },
    closeRateDetail: (state: CarriersStore) => {
      state.rate = null;
      state.isRateModalOpen = false;
    },
  },
});

export const actions = carriersSlice.actions;

export const carriersStore = (state: RootState) => state.carriers;

export default carriersSlice.reducer;

import { Typography } from '@mui/material';
import styled from 'styled-components';

export const OptionText = styled(Typography)`
  width: 100%;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
`;

export const OptionContainer = styled.div`
  max-height: 52px;
  min-height: 0;
  width: 100%;
  display: flex;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
`;

export const ChipsContainer = styled.div`
  max-width: calc(100% - 28px);
`;

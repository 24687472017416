const ROUTES = {
  INDEX: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  ADMIN: '/admin',
  SETTINGS: '/settings',
  ORDERS: '/orders',
  DELIVERY_NOTES: '/delivery-notes',
  SETTLEMENTS: '/settlements',
  DOCUMENT_NOTIFICATIONS: '/document-notifications',
  USERS: '/users',
  SMTPS: '/smtps',
  CLIENTS: '/clients',
  CLIENT: '/clients/:id/',
  SUPPLIERS: '/suppliers',
  SUPPLIER: '/suppliers/:id/',
  INVOICES: '/invoices',
  BOXES_CONTROL: '/boxes-control',
  PAYMENT_MANAGER: '/payment-manager',
  DOCUMENT_MANAGER: '/documents',
  KILOS_REPORT: '/kilos-report',
  CARRIERS: '/carriers',
  CARRIER: '/carrier/:id/',
};

export default ROUTES;

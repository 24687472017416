import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { StyledInput } from './styles';
import type { DateTimePickerProps } from './types';

const DateTimePicker = ({ id, includeErrorSpace = true, error, sx, ...props }: DateTimePickerProps) => {
  const Input = StyledInput as typeof StyledInput;
  return (
    <FormControl variant="standard" sx={sx}>
      {props.label && (
        <InputLabel shrink htmlFor={id}>
          {props.label}
        </InputLabel>
      )}
      <Input {...props} />
      {(includeErrorSpace || error) && <FormHelperText error>{error || ' '}</FormHelperText>}
    </FormControl>
  );
};

export default DateTimePicker;

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { CloseButton, Dialog, DialogContent, DialogFooter, DialogHeader, Title } from './styles';
import type { DialogContainerProps } from './types';

const DialogContainer = ({
  open,
  onClose,
  title,
  children,
  width,
  footer,
  disableEnforceFocus,
  ...rest
}: DialogContainerProps) => {
  return (
    <Dialog
      open={open}
      aria-describedby={`${title} dialog`}
      sx={{ ...rest }}
      PaperProps={{ sx: { ...(width ? { width } : {}) } }}
      keepMounted
      disableEnforceFocus={disableEnforceFocus}
      onClose={onClose}>
      <DialogHeader>
        <Title variant="h4">{title}</Title>
        {onClose ? (
          <CloseButton aria-label="close" onClick={onClose}>
            <CloseOutlinedIcon fontSize="medium" />
          </CloseButton>
        ) : null}
      </DialogHeader>
      <DialogContent>{children}</DialogContent>
      <DialogFooter>{footer}</DialogFooter>
    </Dialog>
  );
};
export default DialogContainer;
